
import Vue from "vue";
import i18n from "@/i18n";
import Router from "@/router";
import { SaveRes } from "@/models/saveres";
import { Snackbar } from "@/models/snackbar";

export default Vue.extend({
  name: "ResetPassword",
  data: () => ({
    pwd: "",
    pwdConf: "",
    isFormvalid: false,
    rules: {
      pwd: [
        (v: string) => !!v || i18n.t("rules.required"),
        (v: string) => v.length > 5 || i18n.t("rules.morethan5char"),
        (v: string) =>
          /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(v) ||
          i18n.t("rules.fancypassword"),
      ],
    },
    lang: [
      { title: "En", value: "en" },
      { title: "Fr", value: "fr" },
      { title: "Es", value: "es" },
    ],
  }),
  computed: {
    resetPasswordResult() {
      return this.$store.getters["auth/getResetPasswordResult"];
    },
    tokenInRoute() {
      return this.$route.query.token as string;
    },
    wholeSnack: {
      get() {
        return this.$store.getters["general/getWholeSnack"];
      },
      set(snack: Snackbar) {
        return this.$store.dispatch("general/changeSnackbar", snack);
      },
    },
  },
  methods: {
    checkMatch() {
      return this.pwd && this.pwdConf && this.pwd == this.pwdConf;
    },
    async resetPassword(token: string, pwd: string, pwdConf: string) {
      await this.$store.dispatch("auth/resetPassword", {
        token: token,
        password: pwd,
        confirmPassword: pwdConf,
      });
      return this.resetPasswordResult as SaveRes;
    },
    validate() {
      this.isFormvalid = (
        this.$refs.form as Vue & {
          validate: () => boolean;
        }
      ).validate();
      if (this.isFormvalid && this.checkMatch) {
        this.resetPassword(this.tokenInRoute, this.pwd, this.pwdConf)
          .then((e) => {
            if (e.status == 200) {
              let snack = {
                isSnacking: true,
                snackColor: "success",
                snackMessage: this.$t("basic.changepwdsuccess").toString(),
                snackTimeout: "2000",
              };
              this.wholeSnack = snack;
              Router.push("/login");
            }
          })
          .catch((err) => {
            if (err.response.status == 422) {
              let snack = {
                isSnacking: true,
                snackColor: "orange",
                snackMessage:
                  this.$t("basic.savewarning").toString() +
                  err.response.statusText,
                snackTimeout: "2000",
              };
              this.wholeSnack = snack;
            } else {
              let snack = {
                isSnacking: true,
                snackColor: "error",
                snackMessage:
                  this.$t("basic.saveerror").toString() +
                  err.response.statusText,
                snackTimeout: "2000",
              };
              this.wholeSnack = snack;
            }
          });
      }
    },
  },
});
